import React, { Component } from 'react'
import { graphql } from 'gatsby';

import SignLayout from '../layouts/SignLayout';
import SEO from '../components/SEO/SEO';
import TopStatic from '../components/TopStatic/TopStatic';
import TermsComponent from '../containers/Terms/Terms';
import CountryContext from '../context/CountryContext';
let title = "Terms and Conditions of GOJO"
let description = "These following Terms and Conditions are the legal agreements between you and GOJO, which will outline your obligations when using GOJO website."
class Terms extends Component {
	static contextType = CountryContext

	compare = (a, b) => {
		if (a.last_nom < b.last_nom) {
			return -1;
		}
		if (a.last_nom > b.last_nom) {
			return 1;
		}
		return 0;
	}

	convertDataByCountry = (data, countryCode, fleetId) => {
		let contents = data && data.termsJson && data.termsJson.edges || []

		let childTermsJsons = contents.map((content) => {
			let childTermsJson = content && content.node && content.node.childTermsJson || {}
			return childTermsJson
		})

		// sort
		childTermsJsons.sort((a, b) => (a.sort > b.sort) ? 1 : ((b.sort > a.sort) ? -1 : 0))
		console.log('childTermsJsons', childTermsJsons)
		if (childTermsJsons.length >= 0) {
			let content = childTermsJsons[0]
			if (countryCode === 'no') {
				content = childTermsJsons[1]
			}
			if (countryCode === 'gb') {
				content = childTermsJsons[2]
			}
			if (countryCode === 'us') {
				content = childTermsJsons[3]
				if (fleetId === 'gojotexas') {
					content = childTermsJsons[4]
				}
			}
			if (countryCode === 'ph') {
				content = childTermsJsons[5]
			}
			if (countryCode === 'jo') {
				content = childTermsJsons[6]
			}
			if (countryCode === 'in') {
				content = childTermsJsons[7]
			}
			if (countryCode === 'th') {
				content = childTermsJsons[8]
			}
			return content && content.contents
		}
		return {}
	}

	render() {
		const { country, fleetId } = this.context || {}

		const { data } = this.props || {};
		const contents = this.convertDataByCountry(data, country, fleetId);

		return (
			<SignLayout
				spotLight={false}
			>
				<SEO title={title} description={description} />
				<TopStatic
					image={`terms.png`}
				/>
				<div className="h-container termsPrivacy">
					<TermsComponent
						// terms={terms}
						contents={
							contents
						}
					/>
				</div>
			</SignLayout>
		)
	}
}

export default Terms;

export const query = graphql`
	query Terms {
		termsJson: allFile(
		filter: { relativeDirectory: { eq: "terms" } }
		) {
		edges {
			node {
			childTermsJson {
          pageTitle,
					metaDescription,
					sort,
					contents {
						stage,
						stage_sub,
						stage_content {
							title,
							title_main,
							content
						}
					}
				}
			}
		}
		}
	}
`