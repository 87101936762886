import React, { Component } from 'react';

import HImage from './../HImage/HImageByPath';

import './TopStatic.scss';

class TopStatic extends Component {
    render() {
        const {
            title = "",
            image
        } = this.props || {};
        return (
            <div className="hmb-top">
              {title && <div className="hmb-top__title">
                  <h1>
                      {
                          title
                      }
                  </h1>
              </div>}
              <div className="hmb-top__image">
                  <HImage
                      noBlur={true}
                      filename={image}
                  />
              </div>
            </div>
        );
    }
}

export default TopStatic;