import React, { Component } from 'react'
import {
  Row,
  Col,
  Accordion,
  Card,
  Tab,
  Nav
} from 'react-bootstrap';
import {
  ChevronDoubleDown
} from 'react-bootstrap-icons';
import Linkify from 'react-linkify'

// import './WeTakeYou.scss';
import { injectIntl } from "gatsby-plugin-intl"

class Terms extends Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef();
  }
  onExit = (e) => {
    this.myRef.current.scrollTop = 0
  }
	render() {
		const { terms, contents } = this.props || {};
		return (
			<div id="terms">
				<Row className="imgBaner d-block d-sm-none">
					<Col xs={12} >
            <Accordion defaultActiveKey="0" bsPrefix="activeColor">
              {
                contents && contents.map((content, id) => {
                return (
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey={`${id}`}>
                      {
                        content.stage
                      }
                      <ChevronDoubleDown />
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={`${id}`}>
                      <Card.Body>
                        <h2>{content.stage} <br /> {content.stage_sub}</h2>
                        {
                          content.stage_content && content.stage_content.map((childTerms, index) => {
                            if(childTerms.title_main) {
                              return (
                                <h2 key={index}>{childTerms.title_main}</h2>
                              )
                            }
                            return (
                              <div key={index} className="termsPrivacy__content">
                                {childTerms.title && <h3>{childTerms.title}</h3>}
                                {childTerms.content.map((ob, index) => (
                                  <p key={index}><Linkify>{ob}</Linkify></p>
                                ))}
                              </div>
                            )
                          })
                        }
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                )
              })}
            </Accordion>
            {/* <div className="term_download">
              <a href="https://test.gojo.asia/images/GOJO_Terms_and_Privacy_Policy.pdf" download>
                <img 
                  src={'../../../images/download.png'}
                />
                Download PDF
              </a>
            </div> */}
					</Col>
				</Row>
        <div className="imgBaner d-none d-sm-block">
            <Tab.Container id="top-tabs-example" defaultActiveKey="0" bsPrefix="activeColor">
              <Row>
                <Col sm={4} className="term_left">
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      {
                        contents && contents.map((content, id) => {
                          return <Nav.Link eventKey={`${id}`}>{content.stage}</Nav.Link>
                        })
                      }
                    </Nav.Item>
                  </Nav>
                  {/* <div className="term_download">
                      <a href="https://test.gojo.asia/images/GOJO_Terms_and_Privacy_Policy.pdf" download> 
                        <img 
                          src={'../../../images/download.png'}
                        />
                        Download PDF
                      </a>
                  </div> */}
                </Col>
                <Col sm={8} className="term_right" ref={this.myRef}>
                  <Tab.Content>
                    {
                      contents && contents.map((content, id) => {
                        return <Tab.Pane 
                                eventKey={`${id}`}
                                onExit={this.onExit}
                               >
                                  <h2>{content.stage} <br /> {content.stage_sub}</h2>
                                  {
                                    content.stage_content.map((childTerms, index) => {
                                      if(childTerms.title_main) {
                                        return (
                                          <h2 key={index}>{childTerms.title_main}</h2>
                                        )
                                      }
                                      return (
                                        <div key={index} className="termsPrivacy__content">
                                          {childTerms.title && <h3>{childTerms.title}</h3>}
                                          {childTerms.content.map((ob, index) => (
                                            <p key={index}>
                                              <Linkify>{ob}</Linkify>
                                            </p>
                                          ))}
                                        </div>
                                      )
                                    })
                                  }
                                </Tab.Pane>
                      })
                    }
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
        </div>
			</div>
		)
	}
}

export default injectIntl(Terms)